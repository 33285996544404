var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.$route.meta.title + (_vm.guiaAuditada ? " Auditada" : ""),
          items: _vm.$route.meta.breadcrumbs
        }
      }),
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, variant: "light", opacity: 0.85 } },
        [
          _c("div", { attrs: { loading: _vm.loading } }, [
            !_vm.disabled && !_vm.guiaAuditada
              ? _c(
                  "div",
                  { staticClass: "hstack gap-3" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "authorize",
                            rawName: "v-authorize",
                            value: "lotesScLotesAuditar",
                            expression: "'lotesScLotesAuditar'"
                          }
                        ],
                        attrs: { pill: "", variant: "success" },
                        on: { click: _vm.salvar }
                      },
                      [
                        _c("i", { staticClass: "uil uil-file-bookmark-alt" }),
                        _vm._v(" Salvar ")
                      ]
                    ),
                    !_vm.pendenteRetorno
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "authorize",
                                rawName: "v-authorize",
                                value: "lotesScLotesAuditar",
                                expression: "'lotesScLotesAuditar'"
                              }
                            ],
                            attrs: { pill: "", variant: "primary" },
                            on: { click: _vm.encerrarAuditoria }
                          },
                          [
                            _c("i", { staticClass: "uil uil-save" }),
                            _vm._v(" Encerrar auditoria do item ")
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        staticClass: "ms-auto",
                        attrs: { pill: "", variant: "danger" },
                        on: { click: _vm.voltar }
                      },
                      [
                        _c("i", { staticClass: "uil uil-times-circle" }),
                        _vm._v(" Fechar ")
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "card mt-4" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("h5", [_vm._v("Itens para glosa")]),
                  _c("b-table", {
                    attrs: {
                      striped: "",
                      hover: "",
                      responsive: "sm",
                      items: _vm.itensTrabalhados,
                      fields: _vm.fields
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(tabelaDescritivo)",
                        fn: function(data) {
                          return [
                            _vm.itemEmEdicao
                              ? _c(
                                  "div",
                                  { staticStyle: { width: "160px" } },
                                  [
                                    _c("f-selectsearch", {
                                      ref:
                                        "tabelaDescritivo" +
                                        data.item.sequencial,
                                      attrs: {
                                        name:
                                          "tabelaDescritivo" +
                                          data.item.sequencial,
                                        searchFunction:
                                          _vm.pesquisarTabelaDominio,
                                        searchAllOptions: true,
                                        removeOptionsOnSelect: false,
                                        meta: {
                                          sequencial: data.item.sequencial
                                        }
                                      },
                                      on: { select: _vm.addTabelaDominio }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    class: {
                                      "dado-editado":
                                        data.item.tabelaCodigo !=
                                          data.item.tabelaCodigoOri &&
                                        data.item.tabelaCodigoOri
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(data.item.tabelaDescritivo) +
                                        " "
                                    )
                                  ]
                                )
                          ]
                        }
                      },
                      {
                        key: "cell(procedimentoCodigo)",
                        fn: function(data) {
                          return [
                            _vm.itemEmEdicao
                              ? _c(
                                  "div",
                                  { staticStyle: { width: "160px" } },
                                  [
                                    _c("f-selectsearch", {
                                      ref:
                                        "procedimentoCodigo" +
                                        data.item.sequencial,
                                      attrs: {
                                        name:
                                          "procedimentoCodigo" +
                                          data.item.sequencial,
                                        disabled: !(_vm.model.tabelaId > 0),
                                        searchFunction:
                                          _vm.pesquisarProcedimentoPorCodigo,
                                        labelField: "codigo",
                                        meta: {
                                          sequencial: data.item.sequencial
                                        }
                                      },
                                      on: {
                                        select: _vm.addProcedimentoPorCodigo
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    class: {
                                      "dado-editado":
                                        data.item.procedimentoCodigo !=
                                          data.item.procedimentoCodigoOri &&
                                        data.item.procedimentoCodigoOri
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(data.item.procedimentoCodigo) +
                                        " "
                                    )
                                  ]
                                )
                          ]
                        }
                      },
                      {
                        key: "cell(procedimentoDescricao)",
                        fn: function(data) {
                          return [
                            _vm.itemEmEdicao
                              ? _c(
                                  "div",
                                  { staticStyle: { width: "250px" } },
                                  [
                                    _c("f-selectsearch", {
                                      ref:
                                        "procedimentoDescricao" +
                                        data.item.sequencial,
                                      attrs: {
                                        name:
                                          "procedimentoDescricao" +
                                          data.item.sequencial,
                                        disabled: !(_vm.model.tabelaId > 0),
                                        searchFunction:
                                          _vm.pesquisarProcedimentoPorDescricao,
                                        labelField: "descricao",
                                        meta: {
                                          sequencial: data.item.sequencial
                                        }
                                      },
                                      on: {
                                        select: _vm.addProcedimentoPorDescricao
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    class: {
                                      "dado-editado":
                                        data.item.procedimentoDescricao !=
                                          data.item.procedimentoDescricaoOri &&
                                        data.item.procedimentoDescricaoOri
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.item.procedimentoDescricao
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                          ]
                        }
                      },
                      {
                        key: "cell(actions)",
                        fn: function(data) {
                          return [
                            !_vm.itemEmEdicao &&
                            !_vm.isModelEditado(data.item.sequencial)
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "outline-primary",
                                      size: "sm",
                                      title: "Alterar procedimento"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editarLinha(
                                          data.item.sequencial
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "uil uil-exchange icon-size"
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm.itemEmEdicao
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "outline-primary",
                                      size: "sm",
                                      title: "Aceitar alterações"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.salvarLinha(
                                          data.item.sequencial
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "uil uil-save icon-size"
                                    })
                                  ]
                                )
                              : _vm._e(),
                            !_vm.itemEmEdicao &&
                            _vm.isModelEditado(data.item.sequencial)
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "outline-primary",
                                      size: "sm",
                                      title: "Desfazer alterações"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.restaurarLinha(
                                          data.item.sequencial
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "uil uil-history icon-size"
                                    })
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row gy-4" }, [
                  _c("div", { staticClass: "col-12 col-md-4" }, [
                    _c("h5", [_vm._v("Glosa")]),
                    _c(
                      "div",
                      { staticClass: "filtro-inline" },
                      [
                        _c(
                          "b-form-group",
                          [
                            _c("b-form-radio-group", {
                              attrs: {
                                id: "auditoriaTipo",
                                options: _vm.auditoria.tipos,
                                name: "auditoriaTipo",
                                disabled: _vm.disabled || _vm.guiaAuditada
                              },
                              model: {
                                value: _vm.auditoria.tipo,
                                callback: function($$v) {
                                  _vm.$set(_vm.auditoria, "tipo", $$v)
                                },
                                expression: "auditoria.tipo"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _vm.auditoria.tipo == "U"
                        ? _c("div", { staticClass: "col-8 px-2" }, [
                            _c("div", { staticClass: "card" }, [
                              _c(
                                "div",
                                { staticClass: "card-body p-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "d-block text-center" },
                                    [_vm._v("Glosa Quantidade(Qtd)")]
                                  ),
                                  _c("money", {
                                    directives: [
                                      {
                                        name: "debounce",
                                        rawName: "v-debounce:450",
                                        value: _vm.calcularGlosa,
                                        expression: "calcularGlosa",
                                        arg: "450"
                                      }
                                    ],
                                    staticClass: "text-end form-control",
                                    attrs: {
                                      disabled: _vm.disabled || _vm.guiaAuditada
                                    },
                                    model: {
                                      value: _vm.auditoria.valorUnitario,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.auditoria,
                                          "valorUnitario",
                                          $$v
                                        )
                                      },
                                      expression: "auditoria.valorUnitario"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.auditoria.tipo == "P"
                        ? _c("div", { staticClass: "col-8 px-2" }, [
                            _c("div", { staticClass: "card" }, [
                              _c(
                                "div",
                                { staticClass: "card-body p-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "d-block text-center" },
                                    [_vm._v("% Percentual")]
                                  ),
                                  _c(
                                    "money",
                                    _vm._b(
                                      {
                                        directives: [
                                          {
                                            name: "debounce",
                                            rawName: "v-debounce:450",
                                            value: _vm.calcularGlosa,
                                            expression: "calcularGlosa",
                                            arg: "450"
                                          }
                                        ],
                                        staticClass: "text-end form-control",
                                        attrs: {
                                          disabled:
                                            _vm.disabled || _vm.guiaAuditada
                                        },
                                        model: {
                                          value: _vm.auditoria.valorPercentual,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.auditoria,
                                              "valorPercentual",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "auditoria.valorPercentual"
                                        }
                                      },
                                      "money",
                                      _vm.inputPercentual,
                                      false
                                    )
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.auditoria.tipo == "D"
                        ? _c("div", { staticClass: "col-8 px-2" }, [
                            _c("div", { staticClass: "card" }, [
                              _c(
                                "div",
                                { staticClass: "card-body p-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "d-block text-center" },
                                    [_vm._v("Valor Item")]
                                  ),
                                  _c("money", {
                                    directives: [
                                      {
                                        name: "debounce",
                                        rawName: "v-debounce:450",
                                        value: _vm.calcularGlosa,
                                        expression: "calcularGlosa",
                                        arg: "450"
                                      }
                                    ],
                                    staticClass: "text-end form-control",
                                    attrs: {
                                      disabled: _vm.disabled || _vm.guiaAuditada
                                    },
                                    model: {
                                      value: _vm.auditoria.desconto,
                                      callback: function($$v) {
                                        _vm.$set(_vm.auditoria, "desconto", $$v)
                                      },
                                      expression: "auditoria.desconto"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.auditoria.tipo != "D",
                              expression: "auditoria.tipo != 'D'"
                            }
                          ],
                          staticClass: "col-4 px-2"
                        },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c(
                              "div",
                              { staticClass: "card-body p-2" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-block text-center",
                                    attrs: { abbr: "Quantidade" }
                                  },
                                  [_vm._v("Qntd")]
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "debounce",
                                      rawName: "v-debounce:450",
                                      value: _vm.calcularGlosa,
                                      expression: "calcularGlosa",
                                      arg: "450"
                                    }
                                  ],
                                  staticClass: "text-end",
                                  attrs: {
                                    type: "tel",
                                    disabled: _vm.disabled || _vm.guiaAuditada
                                  },
                                  model: {
                                    value: _vm.auditoria.quantidade,
                                    callback: function($$v) {
                                      _vm.$set(_vm.auditoria, "quantidade", $$v)
                                    },
                                    expression: "auditoria.quantidade"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-12 col-md-8" }, [
                    _c("h5", [_vm._v("Resultado da Glosa")]),
                    _c("div", { staticClass: "row align-items-end" }, [
                      _c("div", { staticClass: "col-6 col-md-3 px-2 m-0" }, [
                        _c("div", { staticClass: "card" }, [
                          _c(
                            "div",
                            { staticClass: "card-body p-2" },
                            [
                              _c(
                                "label",
                                { staticClass: "d-block text-center" },
                                [_vm._v("Glosa Quantidade(Qtd) auditado")]
                              ),
                              _c("money", {
                                staticClass: "text-end form-control",
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.model.valorUnitarioAuditado,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "valorUnitarioAuditado",
                                      $$v
                                    )
                                  },
                                  expression: "model.valorUnitarioAuditado"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "col-6 col-md-3 px-2" }, [
                        _c("div", { staticClass: "card" }, [
                          _c(
                            "div",
                            { staticClass: "card-body p-2" },
                            [
                              _c(
                                "label",
                                { staticClass: "d-block text-center" },
                                [_vm._v("Quantidade auditada")]
                              ),
                              _c("b-form-input", {
                                staticClass: "text-end",
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.modelQuantidadeAuditada,
                                  callback: function($$v) {
                                    _vm.modelQuantidadeAuditada = $$v
                                  },
                                  expression: "modelQuantidadeAuditada"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "col-6 col-md-3 px-2" }, [
                        _c("div", { staticClass: "card" }, [
                          _c(
                            "div",
                            { staticClass: "card-body p-2" },
                            [
                              _c(
                                "label",
                                { staticClass: "d-block text-center" },
                                [_vm._v("Total glosa")]
                              ),
                              _c("money", {
                                staticClass: "text-end form-control",
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.model.valorGlosa,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "valorGlosa", $$v)
                                  },
                                  expression: "model.valorGlosa"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "col-6 col-md-3 px-2" }, [
                        _c("div", { staticClass: "card" }, [
                          _c(
                            "div",
                            { staticClass: "card-body p-2" },
                            [
                              _c(
                                "label",
                                { staticClass: "d-block text-center" },
                                [_vm._v("Valor total auditado")]
                              ),
                              _c("money", {
                                staticClass: "text-end form-control",
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.model.valorTotalAuditado,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "valorTotalAuditado",
                                      $$v
                                    )
                                  },
                                  expression: "model.valorTotalAuditado"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row gy-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("h5", [_vm._v("Observações sobre a glosa")]),
                      _c("b-form-textarea", {
                        attrs: {
                          id: "observacaoGlosa",
                          disabled: _vm.disabled || _vm.guiaAuditada
                        },
                        model: {
                          value: _vm.auditoria.observacaoGlosa,
                          callback: function($$v) {
                            _vm.$set(_vm.auditoria, "observacaoGlosa", $$v)
                          },
                          expression: "auditoria.observacaoGlosa"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "col pe-4" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-filtroMotivos",
                            "label-cols": "auto",
                            label: "Tabela de motivos",
                            "label-for": "input-filtroMotivos"
                          }
                        },
                        [
                          !_vm.disabled && !_vm.guiaAuditada
                            ? _c("b-form-input", {
                                directives: [
                                  {
                                    name: "debounce",
                                    rawName: "v-debounce:450",
                                    value: _vm.filtrarMotivos,
                                    expression: "filtrarMotivos",
                                    arg: "450"
                                  }
                                ],
                                staticClass: "ms-2",
                                attrs: {
                                  id: "input-filtroMotivos",
                                  placeholder: "Pesquisar motivo"
                                },
                                model: {
                                  value: _vm.carregarMotivosFiltro,
                                  callback: function($$v) {
                                    _vm.carregarMotivosFiltro = $$v
                                  },
                                  expression: "carregarMotivosFiltro"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass: "col-auto d-none d-md-block",
                    staticStyle: { width: "62px" }
                  }),
                  _c("div", { staticClass: "col d-none d-md-block" })
                ]),
                _c(
                  "div",
                  { staticClass: "row flex-column flex-md-row gy-2 gy-md-0" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.disabled && !_vm.guiaAuditada,
                            expression: "!disabled && !guiaAuditada"
                          }
                        ],
                        staticClass: "col"
                      },
                      [
                        _c("b-form-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: _vm.isAnyModelEditado() },
                              expression: "{required: isAnyModelEditado()}"
                            }
                          ],
                          ref: "selectMotivosNS",
                          staticClass: "lista-motivos",
                          attrs: {
                            options: _vm.motivosNaoSelecionados,
                            disabled: !_vm.isAnyModelEditado(),
                            "data-vv-as": "Motivos",
                            multiple: "",
                            "select-size": 7
                          },
                          nativeOn: {
                            scroll: function($event) {
                              return _vm.handleScrollMotivos($event)
                            }
                          },
                          model: {
                            value: _vm.selecionadosLeft,
                            callback: function($$v) {
                              _vm.selecionadosLeft = $$v
                            },
                            expression: "selecionadosLeft"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.disabled && !_vm.guiaAuditada
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-auto d-flex justify-content-center align-items-center",
                            style: {
                              width: _vm._verticalGroupButton ? "62px" : "auto"
                            }
                          },
                          [
                            _c(
                              "b-button-group",
                              { attrs: { vertical: _vm._verticalGroupButton } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "outline-primary" },
                                    on: { click: _vm.remMotivoAll }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "uil uil-angle-double-left icon-size"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "outline-primary" },
                                    on: { click: _vm.remMotivo }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "uil uil-angle-left-b icon-size"
                                    })
                                  ]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "outline-primary" },
                                    on: { click: _vm.addMotivo }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "uil uil-angle-right-b icon-size"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("b-form-select", {
                          staticClass: "lista-motivos",
                          attrs: {
                            options: _vm.motivosSelecionados,
                            multiple: ""
                          },
                          model: {
                            value: _vm.selecionadosRight,
                            callback: function($$v) {
                              _vm.selecionadosRight = $$v
                            },
                            expression: "selecionadosRight"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "row g-3 align-items-center" },
                  [
                    _c("f-switch", {
                      attrs: {
                        name: "pendenteRetorno",
                        disabled: _vm.disabled,
                        labelTrue: "Pendente",
                        labelFalse: "Trânsito normalizado",
                        label: "Pendente retorno hospital",
                        variant: "danger",
                        cols: 12,
                        invertColor: ""
                      },
                      model: {
                        value: _vm.pendenteRetorno,
                        callback: function($$v) {
                          _vm.pendenteRetorno = $$v
                        },
                        expression: "pendenteRetorno"
                      }
                    })
                  ],
                  1
                ),
                _vm.pendenteRetorno
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("f-selectsearch", {
                          directives: [
                            {
                              name: "authorize",
                              rawName: "v-authorize:disable",
                              value: "admConvCriar",
                              expression: "'admConvCriar'",
                              arg: "disable"
                            }
                          ],
                          ref: "pesquisarPendenciaAuditoria",
                          attrs: {
                            label: "Motivo da Pendência",
                            placeholder:
                              "Pesquisar motivos de pendências do hospital",
                            name: "pesquisarPendenciaAuditoria",
                            cols: 12,
                            searchFunction:
                              _vm.pesquisarMotivoPendenciaAuditoria,
                            hideSelected: false,
                            disabled: _vm.disabled,
                            "min-length": 2
                          },
                          on: { select: _vm.addMotivoPendenciaAuditoria }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]),
            !_vm.disabled && !_vm.guiaAuditada
              ? _c(
                  "div",
                  { staticClass: "hstack gap-3" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "authorize",
                            rawName: "v-authorize",
                            value: "lotesScLotesAuditar",
                            expression: "'lotesScLotesAuditar'"
                          }
                        ],
                        attrs: { pill: "", variant: "success" },
                        on: { click: _vm.salvar }
                      },
                      [
                        _c("i", { staticClass: "uil uil-file-bookmark-alt" }),
                        _vm._v(" Salvar ")
                      ]
                    ),
                    !_vm.pendenteRetorno
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "authorize",
                                rawName: "v-authorize",
                                value: "lotesScLotesAuditar",
                                expression: "'lotesScLotesAuditar'"
                              }
                            ],
                            attrs: { pill: "", variant: "primary" },
                            on: { click: _vm.encerrarAuditoria }
                          },
                          [
                            _c("i", { staticClass: "uil uil-save" }),
                            _vm._v(" Encerrar auditoria do item ")
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        staticClass: "ms-auto",
                        attrs: { pill: "", variant: "danger" },
                        on: { click: _vm.voltar }
                      },
                      [
                        _c("i", { staticClass: "uil uil-times-circle" }),
                        _vm._v(" Fechar ")
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.model.equipe
              ? _c("div", { staticClass: "card mt-4" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h5", [_vm._v("Identificação Equipe")]),
                      _vm._l(_vm.model.equipe, function(eleEquipe, idx) {
                        return _c(
                          "div",
                          { key: "eqpIdx" + idx, staticClass: "d-block" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-3 mb-1" }, [
                                _c("label", [
                                  _c("strong", [
                                    _vm._v("Grau de participação:")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(eleEquipe.grauParticipacao)
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "col-lg-3 mb-1" }, [
                                _c("label", [
                                  _c("strong", [_vm._v("CPF:")]),
                                  _vm._v(" " + _vm._s(eleEquipe.cpf))
                                ])
                              ]),
                              _c("div", { staticClass: "col-lg-3 mb-1" }, [
                                _c("label", [
                                  _c("strong", [_vm._v("Nome:")]),
                                  _vm._v(" " + _vm._s(eleEquipe.nome))
                                ])
                              ]),
                              _c("div", { staticClass: "col-lg-3 mb-1" }, [
                                _c("label", [
                                  _c("strong", [_vm._v("Conselho:")]),
                                  _vm._v(" " + _vm._s(eleEquipe.conselho))
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-3 mb-1" }, [
                                _c("strong", [
                                  _vm._v("Número conselho profissional:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(eleEquipe.numeroConselhoProf)
                                )
                              ]),
                              _c("div", { staticClass: "col-lg-3 mb-1" }, [
                                _c("strong", [_vm._v("UF:")]),
                                _vm._v(" " + _vm._s(eleEquipe.uf))
                              ]),
                              _c("div", { staticClass: "col-lg-3 mb-1" }, [
                                _c("strong", [_vm._v("CBOS:")]),
                                _vm._v(" " + _vm._s(eleEquipe.cbos))
                              ])
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-info px-5",
                  attrs: { type: "button" },
                  on: { click: _vm.voltar }
                },
                [_vm._v("Voltar")]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }